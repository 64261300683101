/* ----- NAVBAR CSS ------ */

.navbar {
    .navbar_nav {
        /* ----- WEB NAVBAR CSS ------ */
        .web_navbar {
            padding: 20px 0px;
            .logo {
                a {
                    img {
                        width: 200px;
                    }
                }
            }
            ul {
                &.social-media {
                    display: flex;
                    li {
                        margin-right: 50px;
                        margin-top: 5px;
                        &:last-child {
                            margin-right: 0px;
                        }
                        a {
                            img {
                                width: 20px;
                            }
                        }
                    }
                }
            }
            ul {
                &.main_menu {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    >li {
                        position: relative;
                        >a {
                            color: $secondary;
                            margin: 0px 10px;
                            height: 60px;
                            line-height: 60px;
                            display: block;
                            position: relative;
                            font-size: 14px;
                            font-family: $font-family-clash-medium;
                            text-transform: uppercase;
                            &::after {
                                content: "";
                                background-color: $primary;
                                height: 1px;
                                border-radius: 3px;
                                width: 100%;
                                max-width: 100%;
                                margin: 0 auto;
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 15px;
                                transform: scaleX(0);
                            }
                            &:hover,
                            &:focus {
                                &::after {
                                    transform: scaleX(1);
                                    transition: all 0.5s ease-in-out 0s;
                                }
                                color: $primary;
                            }
                            &.router-link-active {
                                color: $primary;
                                &::after {
                                    content: "";
                                    background-color: $primary;
                                    height: 3px;
                                    width: 100%;
                                    opacity: 1;
                                    transform: scaleX(1);
                                }
                            }
                        }
                        .dropdown_menubar {
                            background-color: #f7f7f7;
                            min-width: 250px;
                            display: block;
                            position: absolute;
                            top: 70px;
                            left: 0;
                            right: auto;
                            z-index: 2;
                            border-radius: 5px;
                            padding: 15px;
                            &::before {
                                content: "";
                                width: 0;
                                height: 0;
                                border-left: 10px solid transparent;
                                border-right: 10px solid transparent;
                                border-bottom: 15px solid #f7f7f7;
                                position: absolute;
                                top: -15px;
                                left: 61px;
                            }
                            li {
                                height: 30px;
                                line-height: 30px;
                                a {
                                    color: #77838f;
                                    &:hover,
                                    &:focus {
                                        color: $primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        /* ----- MOBILE NAVBAR CSS ------ */
        .mobile_navbar {
            display: none !important;
            .logo {
                position: absolute;
                top: 5px;
                left: 10px;
                z-index: 9;
                outline: none;
                a {
                    img {
                        width: 150px;
                    }
                }
            }
            .mat-accordion {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                .mat-expansion-panel {
                    box-shadow: none;
                    border-radius: 0px;
                    .mat-expansion-panel-header {
                        padding: 0;
                        &.toggler {
                            width: 60px;
                            margin-left: auto;
                            background-color: transparent;
                            .mat-content {
                                display: block !important;
                                height: 60px;
                                .mat-expansion-panel-header-title {
                                    float: right;
                                    border: 1px solid $primary;
                                    padding: 5px;
                                    border-radius: 4px;
                                    position: relative;
                                    top: 10px;
                                    margin-right: 10px;
                                }
                            }
                            /* ----- MOBILE TOGGLE CSS ------ */
                            .mobile_toggler {
                                width: 25px;
                                height: 25px;
                                -webkit-transition: all 0.4s ease;
                                transition: all 0.4s ease;
                                position: relative;
                                left: 0px;
                                span {
                                    display: block;
                                    position: absolute;
                                    top: 12px;
                                    height: 2px;
                                    min-height: 2px;
                                    width: 100%;
                                    -webkit-transition: all 0.4s ease;
                                    transition: all 0.4s ease;
                                    background: $primary;
                                    &::before {
                                        background: $primary;
                                        top: -8px;
                                        position: absolute;
                                        display: block;
                                        right: 0;
                                        width: 100%;
                                        height: 0px;
                                        min-height: 2px;
                                        content: "";
                                        -webkit-transition: all 0.4s ease;
                                        transition: all 0.4s ease;
                                    }
                                    &::after {
                                        left: auto;
                                        right: 0px;
                                        width: 100%;
                                        background: $primary;
                                        bottom: -8px;
                                        position: absolute;
                                        display: block;
                                        height: 0px;
                                        min-height: 2px;
                                        content: "";
                                        -webkit-transition: all 0.4s ease;
                                        transition: all 0.4s ease;
                                    }
                                }
                            }
                        }
                    }
                    &.mat-expanded {
                        .mat-expansion-panel-header {
                            &.toggler {
                                .mobile_toggler {
                                    span {
                                        height: 0px;
                                        min-height: 0px;
                                        &::before {
                                            top: 0px;
                                            transform: rotate(45deg);
                                        }
                                        &::after {
                                            bottom: -2px;
                                            transform: rotate(-45deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .mat-expansion-panel-body {
                        padding: 0;
                        border-top: 1px solid #ccc;
                        background: #fff;
                        .main_menu {
                            width: 100%;
                            justify-content: center !important;
                            position: relative;
                            list-style: none;
                            background: #fefefe;
                            text-align: left;
                            overflow: hidden;
                            z-index: 999;
                            >li {
                                padding: 10px;
                                transition: all 0.5s ease-in-out;
                                border-bottom: 1px solid #e8e8ec;
                                a {
                                    display: flex;
                                    justify-content: space-between;
                                    color: $primary;
                                    width: 100%;
                                    font-weight: 600;
                                    letter-spacing: 0.5px;
                                    img {
                                        position: relative;
                                        top: 7px;
                                        right: 25px;
                                    }
                                    .dropdown_name {
                                        padding: 10px;
                                        font-weight: 600;
                                    }
                                }
                                &.dropdown_menubar {
                                    padding: 0px !important;
                                    mat-expansion-panel-header {
                                        height: 41px !important;
                                    }
                                    .sub_menu {
                                        li {
                                            width: 100%;
                                            border-bottom: 1px solid #e8e8ec;
                                            &:last-child {
                                                border: none;
                                            }
                                            a {
                                                transition: all 0.3s ease-in-out;
                                                padding: 7px 15px;
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .mat-expansion-indicator {
                display: none;
            }
        }
    }
}

// Sticky
.sticky {
    position: fixed;
    top: 0;
    animation: slideInDown 0.7s;
    opacity: 1;
    width: 100%;
    transition: all 0.7s ease-in-out;
    background: $white;
    z-index: 9999;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.141));
}

// Scroll-Top
.scroll-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    background: $bg-secondary;
    width: 30px;
    height: 30px;
    opacity: 0;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: 0.2s ease-in-out;
    transition: all 0.5s ease-in-out;
    z-index: 999;
    &.scroll-top-in {
        opacity: 1 !important;
        transition: all 0.2s ease;
    }
    img {
        width: 15px;
    }
    &:hover {
        background: $bg-primary;
    }
}

// footer-sec
.main-footer {
    background-color: $bg-secondary;
    color: $white;
    padding-top: 100px;
    h3 {
        color: $white;
    }
    p {
        color: $white;
    }
    .footer-links {
        padding-bottom: 75px;
        img {
            width: 100%;
            margin-bottom: 20px;
        }
        ul {
            &.social-media {
                display: flex;
                li {
                    margin-right: 90px;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-right: 0px;
                    }
                    a {
                        img {
                            width: 25px;
                            margin: 0px;
                        }
                    }
                }
            }
        }
        .mail-phone {
            margin-bottom: 10px;
            img {
                width: 18px;
                position: relative;
                bottom: 2px;
                margin: 0px;
            }
        }
        ul {
            &.links {
                li {
                    &:first-child {
                        font-size: 18px;
                        font-weight: 600;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        color: $primary;
                        display: inline-block;
                    }
                    margin-bottom: 10px;
                    a {
                        color: $white;
                        text-transform: uppercase;
                        font-size: 12px;
                    }
                    p {
                        &:first-child {
                            font-weight: 700;
                        }
                        color: $white;
                    }
                }
            }
        }
    }
    .form-section {
        width: 228px;
        .custom-form-group {
            margin: 0px 5px;
            .custom-form-control {
                position: relative;
                span {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    cursor: pointer;
                    img {
                        margin: 0px;
                    }
                }
                .mat-form-field {
                    .mat-form-field-wrapper {
                        .mat-form-field-flex {
                            padding: 0px 25px;
                            .mat-form-field-outline {
                                .mat-form-field-outline-end {
                                    border: 0px;
                                }
                            }
                            .mat-form-field-infix {
                                padding: 10px 0px;
                                border: 0px;
                                border-bottom: 1px solid $primary;
                                input {
                                    color: $white;
                                    width: 85%;
                                    &:-webkit-autofill {
                                        -webkit-text-fill-color: $white;
                                    }
                                    &:-webkit-autofill:focus {
                                        -webkit-text-fill-color: $white;
                                    }
                                    &::placeholder {
                                        color: $white;
                                    }
                                     :-ms-input-placeholder {
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.sub-footer {
    padding: 15px 0px;
    p {
        color: $secondary;
        text-align: center;
        padding: 10px;
        a {
            color: $secondary;
        }
    }
}

@-webkit-keyframes slideInDown {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes slideInDown {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.animate__slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}