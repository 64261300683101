/* ------- COMMON CSS ------ */

.sec-padding-tb {
    padding: 75px 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-clash-display-medium;
}

h1 {
    color: $body-title-color;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
    font-weight: normal;
}

h2 {
    color: $body-title-color;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
    font-weight: normal;
}

h3 {
    color: $body-title-color;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
    font-weight: normal;
}

h4 {
    color: $primary;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: normal;
}

h5 {
    color: $body-title-color;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
    font-weight: normal;
}

h6 {
    color: $body-title-color;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
    font-weight: normal;
}

p {
    color: $body-text-color;
    line-height: 20px;
}

/// ---------- HOME PAGE STYLES ---------- ///
// Banner Swiper
.banner-swiper {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url(../assets/images/grey-strips-bg.svg) no-repeat;
        background-size: cover;
    }
    .swiper-container {
        position: relative;
        width: 100%;
        .swiper-wrapper {
            .swiper-slide {
                min-height: 400px;
                .left-img {
                    .doctor {
                        margin-left: auto;
                        width: 100%;
                        position: relative;
                        z-index: 2;
                    }
                }
                .right-content {
                    p {
                        margin-bottom: 20px;
                    }
                }
            }
            .navigations {
                display: flex;
                position: absolute;
                right: 35%;
                bottom: 10%;
                z-index: 2;
                span {
                    cursor: pointer;
                    &.swiper-button-disabled {
                        svg {
                            path {
                                fill: $gray;
                            }
                        }
                    }
                }
                .banner-pagination {
                    padding: 0px 20px;
                    position: relative;
                    bottom: 3px;
                    span {
                        cursor: default;
                        &.swiper-pagination-current {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}

.banner-section {
    position: relative;
    .banner-fixed-img {
        position: absolute;
        bottom: 0px;
        max-width: 650px;
    }
    .left-img {
        .banner-img {
            margin-left: auto;
            width: 100%;
            position: relative;
            z-index: 2;
        }
        .banner-img-1 {
            margin: auto;
            width: 300px;
        }
    }
    .right-content {
        p {
            margin-bottom: 20px;
        }
    }
}

// Explore Swiper
.explore-swiper {
    padding: 15px 0px;
    box-shadow: 8px 6px 35px rgba(0, 0, 0, 0.15);
    .swiper-container {
        position: relative;
        width: 100%;
        .swiper-wrapper {
            max-width: 550px;
            .swiper-slide {
                span {
                    top: 1px;
                    font-family: $font-family-clash-medium;
                }
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

// Access Like
.access-like {
    p {
        margin-bottom: 20px;
    }
}

// Why Dental Interface
.why-dental-interface {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        min-height: 600px;
        background: url(../assets/images/orange-bg.svg) no-repeat;
        background-size: cover;
    }
    .content {
        position: relative;
        h1 {
            color: white;
        }
        p {
            color: white;
            text-align: center;
        }
        .icon-card {
            padding: 15px;
            border-bottom: 1px solid white;
            margin: 10px 0px;
            &.no-border {
                border: 0px;
            }
            .icon {
                background-color: $bg-primary-light;
                border-radius: 50%;
                width: 55px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                svg {
                    width: 25px;
                }
                &:hover {
                    background-color: white;
                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
            span {
                color: white;
                font-family: $font-family-clash;
                font-size: 24px;
            }
        }
    }
}

// About Dental Interface
.about-dental-interface {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        min-height: 500px;
        background: url(../assets/images/grey-bg.svg) no-repeat;
        background-size: cover;
    }
    .content {
        position: relative;
        h1 {
            color: white;
        }
        p {
            margin-bottom: 20px;
            color: white;
        }
        iframe {
            width: 100%;
            height: 650px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }
}

// Dental Interface Advantages
.dental-interface-advantages {
    .left-content {
        .swiper-container {
            position: relative;
            width: 100%;
            .swiper-wrapper {
                max-width: 550px;
                .swiper-slide {
                    min-height: 500px;
                    p {
                        margin-bottom: 20px;
                    }
                }
            }
            .navigations {
                display: flex;
                position: absolute;
                left: 0px;
                bottom: 0px;
                z-index: 2;
                span {
                    cursor: pointer;
                    &.swiper-button-disabled {
                        svg {
                            path {
                                fill: $gray;
                            }
                        }
                    }
                }
                .advantages-pagination {
                    padding: 0px 20px;
                    position: relative;
                    bottom: 3px;
                    span {
                        cursor: default;
                        &.swiper-pagination-current {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
    .right-img {
        position: relative;
        .hands {
            position: absolute;
            top: 20%;
            right: 0px;
            width: 75%;
        }
    }
}

// Designed For You
.designed-for-you {
    position: relative;
    &.sec-padding-tb {
        padding: 150px 0px;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url(../assets/images/grey-bg.svg) no-repeat;
        background-size: cover;
    }
    .content {
        position: relative;
        h1 {
            color: white;
        }
        p {
            margin-bottom: 20px;
            color: white;
        }
    }
}

// Mission & Vision
.mission-vision {
    .left-img {
        position: relative;
        .woman {
            position: absolute;
            left: 15%;
            bottom: 0px;
            width: 70%;
        }
    }
    .mission {
        background-color: $primary;
        border-radius: 10px;
        padding: 35px;
        width: 75%;
        margin: 25px 5%;
        h1 {
            color: white;
            text-align: center;
        }
        p {
            color: white;
        }
    }
    .vision {
        background-color: $gray;
        border-radius: 10px;
        padding: 35px;
        width: 75%;
        margin: 25px 5%;
        h1 {
            color: white;
            text-align: center;
        }
        p {
            color: white;
        }
    }
}

// Dental Interface Team
.dental-interface-team {
    position: relative;
    &.sec-padding-tb {
        padding: 150px 0px;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url(../assets/images/orange-bg.svg) no-repeat;
        background-size: cover;
    }
    .content {
        position: relative;
        h1 {
            color: white;
        }
        p {
            margin-bottom: 20px;
            color: white;
        }
    }
}

// Testimonials
.testimonials {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url(../assets/images/grey-strips-bg.svg) no-repeat;
        background-size: cover;
    }
    .swiper-container {
        width: 100%;
        margin: 0px auto;
        padding: 25px 0px 75px;
        .swiper-wrapper {
            .swiper-slide {
                padding: 25px;
                border-radius: 10px;
                margin: 0px 17.5px;
                max-width: 315px;
                min-height: 300px;
                background-color: white;
                box-shadow: 2px 1px 30px rgba(0, 0, 0, 0.15);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h6 {
                    color: $primary;
                    font-size: 18px;
                    line-height: 24px;
                }
                p {
                    margin: 0 0px 15px;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }
    .swiper-button-next {
        right: -50px;
        &:after {
            font-size: 20px;
            font-weight: 500;
            color: $primary;
        }
    }
    .swiper-button-prev {
        left: -50px;
        &:after {
            font-size: 20px;
            font-weight: 500;
            color: $primary;
        }
    }
    .swiper-pagination {
        .swiper-pagination-bullet {
            margin: 0 5px;
            background-color: $bg-primary-light;
            width: 10px;
            height: 10px;
            &.swiper-pagination-bullet-active {
                background-color: $primary;
            }
        }
    }
}

// FAQ's
.faqs {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url(../assets/images/grey-bg.svg) no-repeat;
        background-size: cover;
    }
    h1 {
        color: white;
        position: relative;
    }
    .mat-accordion {
        .mat-expansion-panel {
            box-shadow: none;
            background: transparent;
            .mat-expansion-panel-header {
                border-bottom: 1px solid white;
                position: relative;
                transition: all 0.5s ease-in-out 0s;
                padding: 15px 0px;
                height: auto;
                border-radius: 0px;
                &:hover {
                    background: transparent;
                }
                .mat-content {
                    .mat-expansion-panel-header-title {
                        color: white;
                        font-size: 20px;
                        font-weight: 500;
                        padding-right: 25px;
                        letter-spacing: 0.5px;
                        line-height: 24px;
                    }
                }
            }
            .mat-expansion-panel-body {
                padding: 15px 0px 0px;
                p {
                    color: white;
                    margin-bottom: 10px;
                }
            }
            .mat-expansion-indicator {
                width: 25px;
                height: 25px;
                line-height: 25px;
                padding: 3px;
                border: 1px solid white;
                border-radius: 50%;
                &::after {
                    background: url("../assets/images/icons/faqs-arrow.svg") no-repeat;
                    background-size: cover;
                    height: 15px;
                    width: 15px;
                    padding: 0px;
                    transform: rotate(90deg);
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    border: 0px;
                }
            }
            &.mat-expanded {
                margin: 0px;
                .mat-expansion-indicator {
                    transform: rotate(90deg) !important;
                }
            }
        }
    }
}

/// ---------- END OF HOME PAGE STYLES ---------- ///
/// ---------- CONTACT PAGE STYLES ---------- ///
// Contact Form
.contact-form {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url(../assets/images/orange-bg.svg) no-repeat;
        background-size: cover;
    }
    .content {
        position: relative;
        .left-content {
            h2 {
                color: white;
                margin-bottom: 10px;
            }
            h5 {
                color: white;
                margin-bottom: 50px;
            }
        }
        .right-img {
            position: relative;
            min-height: 415px;
            .contact-form-img {
                position: absolute;
                left: 35%;
                bottom: -124px;
                width: 415px;
            }
            .contactus-form-img {
                position: absolute;
                left: 35%;
                bottom: -156px;
                width: 415px;
            }
        }
    }
}

/// ---------- END OF CONTACT US PAGE STYLES ---------- ///
/// ---------- BOOKING PAGE STYLES ---------- ///
.booking-sec {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        min-height: 600px;
        background: url(../assets/images/orange-bg.svg) no-repeat;
        background-size: cover;
    }
    .content {
        position: relative;
        h1 {
            color: white;
        }
        h2 {
            color: $secondary;
            margin: 0px;
        }
        p {
            color: white;
            text-align: center;
        }
        .booking-form {
            background-color: white;
            border-radius: 10px;
            margin-top: 50px;
            padding-top: 50px;
            max-width: 100%;
            .booking-options {
                width: 50%;
                margin: 50px auto 0px;
            }
        }
    }
}

.mat-tab-group {
    margin: 50px;
    .mat-tab-header {
        border: 0px;
    }
    .mat-tab-labels {
        border: 1px solid $primary;
        border-radius: 5px;
        .mat-tab-label {
            width: 33.333%;
            &.mat-tab-label-active {
                background-color: $primary;
                border: 0px;
                color: white;
                opacity: 1;
            }
        }
    }
    .mat-ink-bar {
        background-color: transparent !important;
    }
}

/// ---------- END OF BOOKING PAGE STYLES ---------- ///