.form-section {
    width: 100%;
    .custom-form-group {
        margin: 0px 0px 50px;
        .custom-label {
            margin: 0 0 20px 0;
            display: block;
            font-size: $font-size;
            text-align: center;
            color: $secondary;
        }
        .custom-form-control {
            width: 100%;
            .mat-form-field {
                width: 100%;
                font-size: $font-size;
                .mat-form-field-wrapper {
                    margin: 0px !important;
                    padding-bottom: 0px !important;
                    .mat-form-field-flex {
                        outline: 0px !important;
                        padding: 0px !important;
                        &:hover,
                        &:focus {
                            .mat-form-field-outline {
                                color: rgba(0, 0, 0, 0.12) !important;
                                outline: 0px !important;
                            }
                        }
                        .mat-form-field-outline {
                            outline: 0px !important;
                            border-radius: 0px !important;
                            .mat-form-field-outline-start {
                                min-width: 0;
                                border-left-style: none;
                            }
                            .mat-form-field-outline-end {
                                border-radius: 0px;
                                border: 0px;
                                border-bottom: 1px solid white;
                            }
                        }
                        .mat-form-field-infix {
                            padding: 10px 0px;
                            border: 0px;
                            input,
                            textarea {
                                color: white;
                            }
                            .mat-select {
                                 ::placeholder {
                                    color: $secondary;
                                }
                            }
                        }
                    }
                }
                &.select-box {
                    .mat-form-field-wrapper {
                        .mat-form-field-flex {
                            .mat-form-field-infix {
                                padding: 15px;
                                border: 0px;
                                border-radius: 5px;
                                box-shadow: 1px 1px 35px #ebebeb;
                            }
                        }
                    }
                }
            }
            .mat-radio-label {
                margin-right: 45px;
            }
            .mat-radio-checked {
                .mat-radio-outer-circle {
                    border-color: $primary;
                }
                .mat-radio-inner-circle {
                    background-color: $primary;
                }
            }
             ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #ffffff !important;
                /* Firefox */
                font-weight: 400 !important;
                text-transform: capitalize;
            }
             :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #ffffff !important;
                font-weight: 400 !important;
                text-transform: capitalize;
            }
             ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #ffffff !important;
                font-weight: 400 !important;
                text-transform: capitalize;
            }
            .mat-select-arrow {
                margin: 0 0;
                opacity: 1;
            }
            .mat-icon {
                height: 30px;
                width: 30px;
            }
            .arrow_icon {
                width: 20px;
            }
        }
        .mat-checkbox {
            margin: 5px 10px 5px 0px;
            .mat-checkbox-label {
                font-size: 12px;
            }
            .mat-checkbox-frame {
                background-color: transparent;
                transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
                border-width: 1px;
                border-style: solid;
            }
            .mat-checkbox-frame {
                border-color: rgb(177 177 177);
                border-width: 1px;
            }
        }
        .mat-checkbox-checked {
            &.mat-accent {
                .mat-checkbox-background {
                    background-color: $primary !important;
                }
            }
            .mat-checkbox-label {
                color: $primary;
            }
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 1000s ease-in-out 0s;
    -webkit-text-fill-color: white;
}

.mat-snack-bar-container {
    background-color: $primary;
    .mat-simple-snackbar {
        display: flex;
        justify-content: center;
        span {
            color: white;
        }
    }
}