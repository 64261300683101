/* ------------------------------ BREAK-POINTS ------------------------------ */

$breakpoint-xs-sm: "screen and (min-width:300px) and (max-width: 767px)";
$breakpoint-xs: "screen and (min-width:300px) and (max-width: 599px)";
$breakpoint-sm: "screen and (min-width:600px) and (max-width: 767px)";
$breakpoint-md-lg: "screen and (min-width:768px) and (max-width: 1199px)";
$breakpoint-md: "screen and (min-width:768px) and (max-width: 991px)";
$breakpoint-lg: "screen and (min-width:992px) and (max-width: 1199px)";
$breakpoint-xl: "screen and (min-width:1280px)";
$breakpoint-xxl: "screen and (min-width: 1600px)";
$breakpoint-xxxl: "screen and (min-width: 1920px)";

/* ------------------ MOBILE-PHONES-VERTICAL-HORIZONTAL ------------------ */

@media #{$breakpoint-xs-sm} {
    .sec-padding {
        padding: 25px;
    }
    .sec-padding-tb {
        padding: 25px 0px;
    }
    .sec-margin-b {
        margin-bottom: 25px;
    }
    .navbar {
        .navbar_nav {
            // height: 60px;
            .web_navbar {
                display: none;
            }
            .mobile_navbar {
                display: block !important;
            }
        }
    }
}


/* ------------------------------ MOBILE-PHONES-VERTICAL -------------------- */

@media #{$breakpoint-xs} {}


/* ------------------------------ MOBILE-PHONES-HORIZONTAL ---------------- */

@media #{$breakpoint-sm} {}


/* --------------------- MEDIUM-SCREENS ------------------- */

@media #{$breakpoint-md-lg} {}


/* --------------------- I-PAD-TABLETS-HORIZONTAL ------------------- */

@media #{$breakpoint-md} {
    .navbar {
        .navbar_nav {
            height: 60px;
            .web_navbar {
                display: none;
            }
            .mobile_navbar {
                display: block !important;
            }
        }
    }
}


/* --------------------- I-PAD-TABLETS-HORIZONTAL ------------------- */

@media #{$breakpoint-lg} {}


/* --------------------- LAPTOPS AND DESKTOPS ------------------- */

@media #{$breakpoint-xl} {
    .wrapper {
        max-width: 1200px;
    }
    .navbar {
        .navbar_nav {
            .web_navbar {
                ul {
                    &.main_menu {
                        >li {
                            >a {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* --------------------- LARGE SCREENS ------------------- */

@media #{$breakpoint-xxl} {
    .wrapper {
        max-width: 1400px;
    }
    .testimonials {
        .swiper-container {
            .swiper-wrapper {
                .swiper-slide {
                    padding: 35px;
                    margin: 0px 40.5px;
                    min-height: 450px;
                }
            }
        }
    }
    .contact-form {
        .content {
            .right-img {
                min-height: 500px;
                .contact-form-img {
                    width: 500px;
                    bottom: -76px;
                }
                .contactus-form-img {
                    width: 500px;
                    bottom: -113px;
                }
            }
        }
    }
}


/* --------------------- VERY LARGE SCREENS ------------------- */

@media #{$breakpoint-xxxl} {
    .navbar {
        .navbar_nav {
            .web_navbar {
                .logo {
                    a {
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .banner-swiper {
        .swiper-container {
            .swiper-wrapper {
                .navigations {
                    right: 40%;
                }
            }
        }
    }
    .why-dental-interface {
        .content {
            .icon-card {
                span {
                    font-size: 30px;
                }
            }
        }
    }
    p {
        font-size: 24px !important;
        line-height: 36px !important;
    }
}