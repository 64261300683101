// FONTS-SCSS
@import "fonts";
// COLORS-SCSS
@import "colors";
button,
.mat-checkbox,
.mat-expansion-panel-header,
.mat-expansion-panel-content,
.mat-tab-group,
.mat-radio-button,
.mat-button,
.mat-tab-label,
.mat-tab-link,
table,
.mat-option,
.mat-form-field,
.mat-select,
.mat-button-toggle,
.mat-table,
.mat-input-element,
.mat-cell,
.mat-expansion-panel-header-title,
.mat-stepper-vertical,
.mat-stepper-horizontal,
.mat-expansion-panel-content {
    font-family: $font-family-general !important;
}

.cdk-overlay-container {
    z-index: 9999;
}

// CUSTOM-MODAL
.custom-modal {
    .master-modal {
        position: relative;
        .modal-header {
            border-bottom: 1px solid #dadce0;
            padding: 5px 10px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background: #dadce0;
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            .modal-title {
                margin-bottom: 0;
                line-height: 1.5;
                font-size: 17px;
                font-weight: 600;
                h2 {
                    font-size: 24px;
                    margin-bottom: 0px;
                }
            }
        }
        .modal_close_btn {
            position: absolute;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #dadce0;
            height: 28px;
            width: 28px;
            top: -9px;
            right: -8px;
            border-radius: 50% !important;
            z-index: 9;
            padding: 0 !important;
            cursor: pointer;
            .modal-close {
                font-size: 9px;
                height: 16px;
                width: 16px;
                line-height: 22px;
                text-align: center;
                background: #000;
                color: #fff;
                border-radius: 50%;
                border: 2px solid #000;
                transition: all 0.5s ease-in-out 0ms;
                i {
                    font-size: 14px;
                    font-weight: 700;
                    transition: all 0.5s ease-in-out 0ms;
                    line-height: 16px;
                }
                &:hover {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .mat-dialog-content {
            padding: 0 0px;
            margin: 0 0px;
            max-height: initial;
            overflow: auto;
        }
        .modal-body {
            padding: 15px;
            background-color: #fff;
            max-height: 65vh;
            overflow: auto;
        }
        .modal-footer {
            background-color: #fff;
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            padding: 0.75rem;
            border-top: 1px solid #dee2e6;
            border-bottom-right-radius: calc(0.3rem - 1px);
            border-bottom-left-radius: calc(0.3rem - 1px);
            background-color: #fff !important;
            padding: 0 !important;
            justify-content: flex-end !important;
            .mat-button {
                margin-right: 5px;
                line-height: 30px;
                font-weight: 400;
            }
            .add-btn {
                background: $primary !important;
                color: $white !important;
                border: 1px solid $primary !important;
                border-radius: 4px;
            }
            .delete-btn {
                background: $red !important;
                color: $white !important;
                border: 1px solid $red !important;
                border-radius: 4px;
            }
            .cancel-btn {
                border-radius: 4px;
            }
        }
    }
    .mat-dialog-container {
        padding: 0 !important;
        background: #fff !important;
        box-shadow: none !important;
        overflow: visible !important;
        border-radius: 10px !important;
    }
}