// Universal Colors
$white: #fff;
$black: #000;
$red: red;
$green: green;
$blue: blue;
$yellow: yellow;
$orange: orange;
// Body Colors
$body-bg-color: #fff;
$body-title-color: #ff7000;
$body-text-color: #000;
$border-color: #ddd;
// Primary & Secondary Colors
$primary: #ff7000;
$secondary: #000;
$gray: #908f8d;
$gray-light: #dee2ea;
// Primary & Secondary Background Colors
$bg-primary: #ff7000;
$bg-primary-light: #ffd4b2;
$bg-secondary: #000;
$bg-secondary-light: #000;