.mat-button {
    background-color: #fff;
    border-radius: 55px !important;
    height: 48px;
    padding: 0 20px 0 0px !important;
    transition: all 0.5s ease-in-out 0s;
    overflow: hidden !important;
    filter: drop-shadow(8px 6px 20px rgba(0, 0, 0, 0.1));
    .mat-button-wrapper {
        color: $secondary;
        font-size: 16px;
        transition: all 0.5s ease-in-out 0s;
        font-family: $font-family-clash-medium;
        letter-spacing: 1px;
        img {
            width: 48px;
            height: 48px;
            margin: 0px 10px 0px 0px;
            transition: all 0.5s ease-in-out;
            filter: drop-shadow(8px 6px 20px rgba(0, 0, 0, 0.1));
        }
    }
    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0px;
        width: 0;
        background-color: $primary;
        border-radius: 25px;
        transition: all 0.5s ease;
        transition-delay: 0.2s;
    }
    &:hover {
        &::before {
            transform: scaleX(1);
            transition: all 0.5s ease;
            transition-delay: 0.2s;
            width: 100%;
            height: 100%;
            z-index: auto;
        }
        .mat-button-wrapper {
            color: #fff;
            position: relative;
            z-index: 1;
            transition: all 0.5s ease-in-out 0s;
            img {
                z-index: 2;
            }
        }
    }
}

.btn-primary {
    @extend .mat-button;
}

.btn-secondary {
    @extend .mat-button;
    width: 100%;
    background-color: $primary;
    padding: 0 20px 0 20px !important;
    border-radius: 10px !important;
    .mat-button-wrapper {
        color: white;
        font-size: 16px;
        transition: all 0.5s ease-in-out 0s;
        font-family: $font-family-clash-medium;
        letter-spacing: 1px;
        img {
            width: 16px;
            height: 16px;
            margin: 0px 0px 0px 10px;
        }
    }
    &:hover {
        &::before {
            background-color: $primary;
        }
    }
}

.btn-outline {
    @extend .mat-button;
    width: 100%;
    border: 1px solid $primary !important;
    padding: 0px !important;
    &:hover {
        &::before {
            background-color: $primary;
        }
    }
}