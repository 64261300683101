// RESET-SCSS
@import "reset";
// HELPER-SCSS
@import "helper";
// COLORS-SCSS
@import "colors";
// FONTS-SCSS
@import "fonts";
// BUTTONS-SCSS
@import "buttons";
// FORM-SCSS
@import "form";
// NAVBAR-SCSS
@import "nav";
// CUSTOM-SCSS
@import "custom";
// Responsive-SCSS
@import "responsive";
html,
body {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: $font-family-general;
    font-size: $font-size;
    background: $body-bg-color;
    color: $body-text-color;
    font-weight: 400;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 1px;
}

.wrapper {
    width: 95%;
    margin: auto;
}