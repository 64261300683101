// General Sans
@font-face {
    font-family: "General Sans Light";
    src: url("/assets/fonts/GeneralSans-Light.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "General Sans Extra Light";
    src: url("/assets/fonts/GeneralSans-Extralight.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "General Sans";
    src: url("/assets/fonts/GeneralSans-Regular.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "General Sans Medium";
    src: url("/assets/fonts/GeneralSans-Medium.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "General Sans Semibold";
    src: url("/assets/fonts/GeneralSans-Semibold.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "General Sans Bold";
    src: url("/assets/fonts/GeneralSans-Bold.ttf") format("truetype");
    font-style: normal;
}

// Clash Grotesk
@font-face {
    font-family: "Clash Grotesk Light";
    src: url("/assets/fonts/ClashGrotesk-Light.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Grotesk Extra Light";
    src: url("/assets/fonts/ClashGrotesk-Extralight.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Grotesk";
    src: url("/assets/fonts/ClashGrotesk-Regular.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Grotesk Medium";
    src: url("/assets/fonts/ClashGrotesk-Medium.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Grotesk Semibold";
    src: url("/assets/fonts/ClashGrotesk-Semibold.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Grotesk Bold";
    src: url("/assets/fonts/ClashGrotesk-Bold.ttf") format("truetype");
    font-style: normal;
}

// Clash Display
@font-face {
    font-family: "Clash Display Light";
    src: url("/assets/fonts/ClashDisplay-Light.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Display Extra Light";
    src: url("/assets/fonts/ClashDisplay-Extralight.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Display";
    src: url("/assets/fonts/ClashDisplay-Regular.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Display Medium";
    src: url("/assets/fonts/ClashDisplay-Medium.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Display Semibold";
    src: url("/assets/fonts/ClashDisplay-Semibold.ttf") format("truetype");
    font-style: normal;
}

@font-face {
    font-family: "Clash Display Bold";
    src: url("/assets/fonts/ClashDisplay-Bold.ttf") format("truetype");
    font-style: normal;
}

$font-size: 16px;
$font-family-general: "General Sans";
$font-family-general-medium: "General Sans Medium";
$font-family-clash-display: "Clash Display";
$font-family-clash-display-medium: "Clash Display Medium";
$font-family-clash: "Clash Grotesk";
$font-family-clash-medium: "Clash Grotesk Medium";